.profile_popup-conteiner {
    top: 10%;
    right: 4%;
    position: absolute;
    height: 116px;
    background-color: white;
    min-width: 320px;
    border: 1px solid black;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .popup-header {
        width: 100%;
        height: 64px;
        background-color: #153b5e;
        display: flex;
        justify-content: start;
        align-items: center;
        color: white;
        padding: 8px;

    }

    .user-img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: red;
    }

    .user-info-text {
        margin-left: 12px;
        font-size: 14px;
        cursor: pointer;
    }

    .changepassword-text:hover {
        text-decoration: underline;
    }

    .body-item {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: start;
        cursor: pointer;

        .prifile-text {
            margin-left: 24px;
        }

        .profile-icon {
            width: 24px;
            height: 24px;
            margin-left: 12px;
        }
    }

    .body-item:hover {
        background-color: #f5f5f5;
    }

}