.sub-menu-container {
  width: 100%;
  // background-color: #7393B3;
  background: rgb(21,59,94);
  background: linear-gradient(90deg, rgba(21,59,94,1) 0%, rgba(21,59,94,1) 81%, rgba(124,162,197,1) 91%, rgba(113,177,237,1) 100%);
  padding: 8px 0;
  height: 52px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}