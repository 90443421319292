.change-password-modal {
    width: 720px;
    height: auto;
    background-color: white;

    .user-name {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .profile-image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;


        .profile-image {
            width: 64px;
            height: 64px;
            background-color: green;
            border-radius: 50%;
            margin-top: 24px;
            margin-bottom: 12px;
            object-fit: cover;
        }

    }

    .profile-input-wrrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .profile-input-style {
            margin-top: 24px;

            span {
                color: #d4d8de !important;
            }

            input {
                border: none !important;
                border-bottom: 1px solid #d4d8de !important;
            }

            input:disabled {
                color: #d4d8de !important;
            }

        }


    }


    .profile-button-wrraper {
        width: 100%;
        height: 74px;
        display: flex;
        justify-content: center;
        align-items: center;

        .profile-button {
            width: 240px;
            height: 54px;
            background-color: #153b5e;
            font-size: 24px;
            color: white;
            margin-top: 24px;
            margin-bottom: 24px;

            &:hover {
                background-color: white;
                color: #153b5e;
            }
        }
    }

    .pass-header {
        width: 100%;
        height: 64px;
        background-color: #153b5e;
        color: white;
        text-align: center;
        font-size: 24px;
        font-weight: bolder;
        vertical-align: middle;
        padding-top: 12px;
    }

    .wrrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        .profile-upload-image {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .fileupload-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin-top: 24px;
        border: 2px dashed #d4d8de !important;
        padding: 12px 0;
        background-color: #f8f8f8;

        span {
            color: #d4d8de;
        }
    }

    .profile-fileupload-style {
        span {
            color: #d4d8de;
        }

        input {
            opacity: 0 !important;
            width: 100%;
        }

    }
}