.navigation_conteiner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    min-height: 40px;
    background-color: #FFF9ED;
    .brands_popup {
        left: -50%;
    }

    .brands_popup>div {
        display: grid;
        grid-template-columns: auto auto auto;

        .brand_image {
            width: 100px;
            height: 54px;
        }

        .navbar_popup {
            min-width: 140px;
        }
    }

    .navbar_item {
        font-size: 15px;
        font-weight: bolder;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        position: relative;
        color: #153b5e;
        text-transform: uppercase;
        cursor: pointer;
    }

    .active_menu {
        color: #FFA500 !important;
    }

    .popup_conteiner {
        position: absolute;
        top: 102%;
        z-index: 3;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: white;
        box-shadow: 2px 5px 11px 1px rgb(0 0 0 / 47%);

        .sub_container {

            margin: 4px;
            background-color: white;
        }

    }

    .navbar_popup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        min-width: 320px;
        padding: 8px;
        background-color: white;


        .popup_link {
            width: 100%;
            height: auto;
            box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 14%);
            color: #153b5e;
            transition: 0.3s;

            &:hover {
                background-color: lightgrey;
                color: #153b5e !important;
                box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 14%);
                border-radius: 4px;
            }
        }
    }
}

// .static_breadCrumb {
//     width: 100%;
//     height: 56px;
//     background-color: #153b5e;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;

//     .breadCrumb_paths {
//         color: white;
//         font-size: 14px;
//         letter-spacing: 4px;
//         margin-left: 100px;
//         font-weight: bolder;
//     }
// }