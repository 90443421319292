.profile_menu_conteiner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 140px;
    padding-left: 42px;
    position: relative;

    .profile_picture {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .fillDropwDown {
        width: 12px;
        height: 12px;
        cursor: pointer;
    }

    .profile-menu-text {
        cursor: pointer;
    }
}

.black-window-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000075;
    z-index: 12;
    animation-duration: 0.4s;
    animation: open-center;
    transition: all 1s cubic-bezier(0, 0, 1, 1);
    display: flex;
    align-items: center;
}