.changePassword-conteiner {
    .header {
        width: 100%;
        height: 64px;
        background-color: #153b5e;
        text-align: center;
        vertical-align: middle;
        color: #fff;
        padding: 16px;
        font-size: 24px;
        font-weight: bold;
    }
}