@font-face {
  font-family: "Infini-Bold";
  src: url("./assets/fonts/Infini/infini-bold.ttf");
}

@font-face {
  font-family: "Infini";
  src: url("./assets/fonts/Infini/infini-regular.otf");
}

@font-face {
  font-family: "PTSans";
  src: url("./assets/fonts/PTSans/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTSans-bold";
  src: url("./assets/fonts/PTSans/PTSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}