.form-conteiner {
    width: 100%;
    padding: 0px 46px 44px;

    .error-message {
        color: red;
        margin-top: 12px;
    }

    .form-inp {
        height: 58px;
        width: 720px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            margin-top: 24px;
        }

        input {
            margin-top: 24px;
            width: 100%;
            border: none !important;
            border-bottom: 1px solid #153b3e !important;
            background-color: #f5f5f5;
        }

        input::placeholder {
            font-size: 20px;
        }
    }

    .button-wrraper {
        width: 100%;
        height: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;

        .save-button {
            width: 320px;
            height: 54px;
            background-color: #153b5e;
            font-size: 24px;
            color: white;
            margin-top: 24px;
            margin-bottom: 24px;

            &:hover {
                background-color: white;
                color: #153b5e;
            }
        }
    }
}