.modal-black-window {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000075;
    z-index: 12;
    animation-duration: 0.4s;
    animation: open-center;
    transition: all 1s cubic-bezier(0, 0, 1, 1);
    display: flex;
    align-items: center;

    @keyframes open-center {
        from {
            transform: scaleY(0.01);
        }

        to {
            transform: scaleY(1);
        }

    }

    .main-modal {
        background-color: #f5f5f5;
        height: auto;

        transition: all 1s cubic-bezier(0, 0, 1, 1);

        .close-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: black;
            color: #fff;
            font-weight: bolder;
            text-align: center;
            margin-left: auto;
            margin-bottom: 12px;
            right: -10px;
            top: -10px;
            position: absolute;
            cursor: pointer;
        }
    }




}

.CENTER {
    justify-content: center;
    animation-name: linear-modal;
    animation-duration: 0.4s;

    @keyframes linear-modal {
        from {
            transform: scale(0.1);
        }

        to {
            transform: scale(1);
        }

    }


}

.LEFT {
    justify-content: left;

    .main-modal {
        animation: open-left;
        animation-duration: 0.4s;
    }

    @keyframes open-left {
        from {
            transform: translateX(-100%)
        }

        to {
            transform: translateX(0)
        }


    }

}

.RIGHT {
    justify-content: right;
    align-items: start;
    padding-top: 24px;
    padding-right: 24px;

    .main-modal {
        animation: open-right;
        animation-duration: 0.4s;
    }


    @keyframes open-right {
        from {
            transform: translateX(100%)
        }

        to {
            transform: translateX(0)
        }

    }

}

.scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
}